import Styled from 'styled-components';
import React from 'react';

const Container = Styled.div`
display:flex;
align-items: center;
`;

const Image = Styled.div`
border-radius:50px;
background-image:url(${(props) => props.image});
width:50px;
height:50px;
min-width:50px;
`;

const Text = Styled.div`
margin-left:20px;
p {
    margin:0;
    padding:0;
    font-size:12px;
    line-height:20px;
}`;

function ProfileCard (props){
    return (
        <Container>
            <Image image={`${props.image}?fm=jpg&fl=progressive&w=50`} />
            <Text>
                <p>{props.children}</p>
            </Text>
        </Container>
    );

}

export default ProfileCard;
