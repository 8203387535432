import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './font/basis-grotesque-medium-pro.ttf';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
      // keep only serviceWorker which scope is /disable-service-worker/, The purpose is to make serviceWorker useless
      if (registration.scope.includes('/disable-service-worker/') === false) {
        registration.unregister()
      }
    }
  });
  // clear cache of service worker
  caches.keys().then(keyList => {
    return Promise.all(
      keyList.map(key => {
        return caches.delete(key);
      }),
    );
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
