import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from "./routes/home/";
import Kontakt from "./routes/kontakt/";
import Karriar from "./routes/karriar/";
import Yrke from "./routes/yrke";
import Menu from "./components/menu";
import Footer from "./components/footer";
import Styled from 'styled-components';

const Container = Styled.div`
width:100%;
padding:0px 90px;
box-sizing: border-box;
margin:0 auto;
@media (max-width:1024px){
  padding:0px 60px;
}
@media (max-width:768px){
  padding:0px 20px;
}
`;
const FooterContainer = Styled.div`
width:100%;
padding:0px 80px;
box-sizing: border-box;
background-color:#181618;
@media (max-width:1024px){
  padding: 0px 40px;
} 
@media (max-width:768px){
  padding: 0px 20px;
}
`;

function App() {
  return (
    <Router>
    <Container>      
      <Menu />
      <Switch>
        <Route path="/karriar/:id" render={(props) => <Yrke {...props} /> } />        
        <Route path="/karriar">
          <Karriar />
        </Route>
        <Route path="/kontakt">
          <Kontakt />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Container>
    <FooterContainer>
      <Footer />
    </FooterContainer>
  </Router>
  );
}

export default App;
