import React from "react";
import {
    NavLink
  } from "react-router-dom";
import Styled from 'styled-components';
import { client } from '../../contentful/'
import { Instagram, Facebook, LinkedIn } from '../instagram';

const MenuContainer = Styled.div`
    position:relative;
    display:block;
    nav, *  { text-align: right; }
    ul, li { list-style-type: none; }
    li { display:inline-block; margin:66px 0px 66px 40px;}
    a svg {display:none; margin-bottom:-4px; }

    @media (max-width: 1024px) {
        li { display:inline-block; margin:66px 0px 66px 20px;}
        a span { display:none; }
        a svg {display:inline-block;}
    }

    @media (max-width:768px) {
        position:fixed;
        bottom:20px;
        left:50%;
        transform: translateX(-50%);
        z-index:100;
        white-space:nowrap;

        li { margin: 10px;}
        ul { margin: 0px; padding:0px;}
        background: rgba(242, 242, 242, 0.85);
        border-radius:50px;
        overflow-y:hidden;
        overflow-x:hidden;
        padding:20px;

    }

    a {
        text-decoration:none;
        position:relative;
    }
    a.is-active:after {
        content:'';
        display:block;
        width:10px;
        height:2px;
        margin:0 auto;
        background-color:black;
    }
    `

const Logo = Styled.div`
    position:absolute; 
    display:block;
    top:47px;
    margin-left: 0px;
    width:217px;
    height:54px;
    background:URL('https://images.ctfassets.net/db9haos3es81/Q2ppXnoC5MBhHAsIKOmQD/484eba60eeae403f4eb0a4d38fd10826/image.png');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width:768px) {
        left:50%;
        transform:translateX(-50%);
        width:191px;
        top:16px;
    }
`;

const Container = Styled.div`
    min-height:100px;
    width:calc(100vw - 260px);
    margin:0 30px;
    @media (max-width: 1024px) {
        width:calc(100vw - 200px);
    }
`;

function Menu(){

    function scrollToTop(){
        window.scrollTo(0, 0);
    }
    
    client.getEntry("2pNUcGPyqwso9Pgl32Nodd");
    return (
        <Container>
            <Logo />
            <MenuContainer>
                <nav>
                    <ul>
                        <li>
                            <NavLink exact={true} to="/" activeClassName='is-active' onClick={scrollToTop}>Hem</NavLink>
                        </li>
                        <li>
                            <NavLink to="/karriar" activeClassName='is-active' onClick={scrollToTop}>Karriär</NavLink>
                        </li>
                        <li>
                            <NavLink to="/kontakt" activeClassName='is-active' onClick={scrollToTop}>Kontakt</NavLink>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/studioekberg/" target="_blank" rel="noreferrer">
                                <span>Instagram</span>
                                <Instagram width="20" height="20"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/studioekberg" target="_blank" rel="noreferrer">
                                <span>Facebook</span>
                                <Facebook width="20" height="20"/>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/studio-ekberg/" target="_blank" rel="noreferrer">
                                <span>Linkedin</span>
                                <LinkedIn width="20" height="20"/>
                            </a>
                        </li>
                    </ul>
                </nav>
            </MenuContainer>
        </Container>
    );
}

export default Menu;

