import React, { useState, useEffect  } from "react";
import { client } from '../../contentful/'
import Styled from 'styled-components';
import Image from '../../components/image';
import { Loading } from "../../components/Loading";


const Container = Styled.div`
    max-width:1024px;
    margin:0 auto;
    margin-bottom:60px;
    width:100%;
`;

const ContactUs = Styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    margin:60px 0px;
    padding: 20px 40px;
    border-radius: 100px;
    text-align: right;
    display:inline-block;
    left:50%;
    position:relative;
    transform:translateX(-50%);
    align-items: center;    
`;

function Yrke(props) {
    const [pageData, setPageData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const entry = props.match.params.id;

    useEffect(() => {
        client
            .getEntry(entry)
            .then((entry) => {
                    setPageData(entry);
                    setIsLoading(false);
                }
            ).catch(err => console.log(err));
    },[entry]);

    return (
        <div>
        { isLoading && (
            <Loading />
        )}
        { !isLoading && (
            <Container>
                <Image image={pageData.fields.bild.fields.file.url} />
                <h1>
                    {pageData.fields.titel}
                </h1>
                <p>{pageData.fields.fullBeskrivning}</p>
                <a href="mailto:kontakt@studioekberg.se"><ContactUs>kontakt@studioekberg.se</ContactUs></a>
            </Container>
            )}
        </div>
        
    );
}

export default Yrke;

