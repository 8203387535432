import styled from 'styled-components';

export const Grid = styled.div``;

export const Row = styled.div`
    display:flex;
    ${(props) => props.direction && media[props.direction](`
    flex-direction: column;
`)};
`;

const media = {
    xs: (styles) => `
        @media only screen and (max-width: 768px){
            ${styles}
        }
    `,
    l: (styles) => `
    @media only screen and (min-width: 768px){
        ${styles}
    }
`,
    lcolumn: (styles) => `
    @media only screen and (min-width: 768px){
        ${styles}
    }`,
    column: (styles) => `
        @media only screen and (max-width: 768px){
            ${styles}
        }
    `


}

export const Col = styled.div`
    flex:${(props) => props.size};
    margin:30px;
    ${(props) => props.collapse && media[props.collapse](`
        display:none;
    `)};

    @media (max-width:768px){
        margin:20px;
    }
`;