import React from 'react';
import Styled from 'styled-components';

const Container = Styled.img`
width:100%;
`;

function Image(props){

    const size = (props.sizes) ? props.sizes : "100vw";

    return(
        <Container
            srcSet={`
                ${props.image}?fm=jpg&fl=progressive&w=200 200w,
                ${props.image}?fm=jpg&fl=progressive&w=400 400w,
                ${props.image}?fm=jpg&fl=progressive&w=800 800w,
                ${props.image}?fm=jpg&fl=progressive&w=1200 1200w,
                ${props.image}?fm=jpg&fl=progressive&w=1400 1400w,
                ${props.image}?fm=jpg&fl=progressive&w=1600 1600w,
                ${props.image}?fm=jpg&fl=progressive&w=1800 1800w,
                ${props.image}?fm=jpg&fl=progressive&w=2000 2000w,
            `}
            sizes={size}
        />
        );
}

export default Image;