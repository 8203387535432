import React, { useState, useEffect } from "react";
import Styled from 'styled-components';
import { client } from '../../contentful/'
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import Image from '../../components/image';
import { Breaker } from '../../components/breaker';
import {Grid, Row, Col} from '../../components/grid';
import Medarbetare from "../../components/medarbetare";
import { Loading } from "../../components/Loading";

const RecruitArea = Styled.div`
    width: calc(100vw / ${(props) => 4 / props.width} - ${(props) => props.width * 60 + 120 }px);    
    background-color:rgba(192, 204, 200, 1);
    height:calc(100% - 86px);
    padding:0px 26px;
    box-sizing: content-box;
    display:flex;
    align-items: center;
    div { text-align:center; margin:0 auto; p, h2 { text-align:center; } p {margin-bottom:60px;} }
    svg { margin:60px auto 0px auto;}

    @media (max-width: 1280px){
           width:calc((100vw / 3 - 122px) * 2)
    }

    @media (max-width: 768px){
        display:none;
    }
`;

const MobileRecrutieArea = Styled.div`
width: calc(100vw - 90px);    
background-color:rgba(192, 204, 200, 1);
padding:0px 26px;
box-sizing: content-box;
display:flex;
align-items: center;
width:calc(100vw - 142px);
div { text-align:center; margin:0 auto; p, h2 { text-align:center; } p {margin-bottom:60px;} }
svg { margin:60px auto 0px auto;}
margin:40px 0px 60px - 20px;

@media (min-width: 768px){
    display:none;
}`;

function Kontakt() {
    const [pageData, setPageData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const entry = "7FxYmrAvVMHylmBUMW4s6C";

    useEffect(() => {
        client
            .getEntry(entry)
            .then((entry) => {
                    setPageData(entry);
                    setIsLoading(false);
                }
            ).catch(err => console.log(err));
    },[entry]);

    const medarbetare = [];
    if(!isLoading){
        let i=0, width = false;
        for (const value of pageData.fields.medarbetare) {
            i++;
            medarbetare.push(<Col key={"medarbetare"+i}><Medarbetare data={value.fields} /></Col>)
            if(!width && (i === 6 || i+1 > pageData.fields.medarbetare.length)){
                width = 4 - (i % 4);
                medarbetare.push(
                <Col collapse="xs" key={"recruitaresmall"+i}>
                    <RecruitArea width={width}>
                        <div>
                            <svg width="10" height="54" viewBox="0 0 10 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 48.8711C0 50.2877 0.492957 51.5088 1.47887 52.5346C2.46479 53.5115 3.6385 54 5 54C6.3615 54 7.53521 53.5115 8.52113 52.5346C9.50704 51.5088 10 50.2877 10 48.8711C10 47.4545 9.50704 46.2334 8.52113 45.2076C7.53521 44.1818 6.3615 43.6689 5 43.6689C3.6385 43.6689 2.46479 44.1818 1.47887 45.2076C0.492957 46.2334 0 47.4545 0 48.8711ZM0.915492 9.5251L4.29577 38.6133H5.70423L9.08451 9.5251C9.22535 8.30394 9.34272 7.327 9.43662 6.5943C9.53052 5.8616 9.57746 5.32429 9.57746 4.98236C9.57746 3.51696 9.15493 2.32022 8.30986 1.39213C7.46479 0.464043 6.3615 0 5 0C3.6385 0 2.53521 0.464043 1.69014 1.39213C0.84507 2.32022 0.422535 3.51696 0.422535 4.98236C0.422535 5.32429 0.469483 5.8616 0.56338 6.5943C0.657277 7.327 0.774647 8.30394 0.915492 9.5251Z" fill="#181618"/>
                            </svg>
                            <h2>{pageData.fields.rekryteringspuff}</h2>
                            <p>{pageData.fields.rekryteringspuffLitenText} <a href={"mailto:"+pageData.fields.rekryteringspuffEpost}>{pageData.fields.rekryteringspuffLnktext}</a></p>
                        </div>
                    </RecruitArea>
                </Col>
                );
            }
        }
        medarbetare.push(
            <Col collapse="l"  key={"recruitarebig"+i}>
                <MobileRecrutieArea>
                    <div>
                        <svg width="10" height="54" viewBox="0 0 10 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 48.8711C0 50.2877 0.492957 51.5088 1.47887 52.5346C2.46479 53.5115 3.6385 54 5 54C6.3615 54 7.53521 53.5115 8.52113 52.5346C9.50704 51.5088 10 50.2877 10 48.8711C10 47.4545 9.50704 46.2334 8.52113 45.2076C7.53521 44.1818 6.3615 43.6689 5 43.6689C3.6385 43.6689 2.46479 44.1818 1.47887 45.2076C0.492957 46.2334 0 47.4545 0 48.8711ZM0.915492 9.5251L4.29577 38.6133H5.70423L9.08451 9.5251C9.22535 8.30394 9.34272 7.327 9.43662 6.5943C9.53052 5.8616 9.57746 5.32429 9.57746 4.98236C9.57746 3.51696 9.15493 2.32022 8.30986 1.39213C7.46479 0.464043 6.3615 0 5 0C3.6385 0 2.53521 0.464043 1.69014 1.39213C0.84507 2.32022 0.422535 3.51696 0.422535 4.98236C0.422535 5.32429 0.469483 5.8616 0.56338 6.5943C0.657277 7.327 0.774647 8.30394 0.915492 9.5251Z" fill="#181618"/>
                        </svg>
                        <h2>{pageData.fields.rekryteringspuff}</h2>
                        <p>{pageData.fields.rekryteringspuffLitenText} <a href={"mailto:"+pageData.fields.rekryteringspuffEpost}>{pageData.fields.rekryteringspuffLnktext}</a></p>
                    </div>
                </MobileRecrutieArea>
            </Col>
        );
    }

    return (
        <div>
        { isLoading && (
            <Loading />
        )}
        { !isLoading && (
            
            <div>
            { pageData.fields.storBild && (
                <Image image={pageData.fields.storBild.fields.file.url} />
            )}
                <Grid>
                    <Row direction="column">
                        <Col size={1}>
                        <h1>
                            {pageData.fields.titel}
                        </h1>
                        </Col>
                        <Col size={1}>
                            <ReactMarkdown remarkPlugins={[gfm]}>{pageData.fields.text}</ReactMarkdown>
                            <div style={{ width:'50% ', display:'inline-block'}}>
                                <ReactMarkdown remarkPlugins={[gfm]}>{pageData.fields.kontaktlnkar}</ReactMarkdown>
                            </div>
                            <div style={{ width:'50% ', display:'inline-block'}}>
                            <ReactMarkdown remarkPlugins={[gfm]}>{pageData.fields.kontaktuppgifter}</ReactMarkdown>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col size={1}>
                            <Breaker />
                        </Col>
                    </Row>
                    <Row>
                        <Col size={1}>
                            <h1>Medarbetare</h1>
                        </Col>
                    </Row>
                    <Row style={{flexFlow: 'row wrap'}}  direction="column">
                        {medarbetare}
                    </Row>
                </Grid>
            </div>
            )}
        </div>
        
    );
}

export default Kontakt;

