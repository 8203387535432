import React, { useState, useEffect } from "react";
import { client } from '../../contentful/'
import Styled from 'styled-components';
import {Grid, Row, Col} from '../../components/grid';
import Image from '../../components/image';
import { Breaker } from "../../components/breaker";
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import ProfileCard from "../../components/profileCard";
import { Loading } from "../../components/Loading";

const TestimonialSplash = Styled.div`
    margin: 60px 0px;
    width:100%;
    height:360px;
    text-align:center;
    background-color:#C0CCC8;
    display:flex;
    align-items: center;
    padding:20px;
    box-sizing:border-box;
`;

const TestimonialSplashContainer = Styled.div`
    max-width:480px;
    margin:0 auto;
`;

const ReadMoreButton = Styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px 18px;
    border-radius: 100px;
    text-align: right;
    display:inline-block;
    align-items: center;
    cursor:pointer;
`;

const YrkePopup = Styled.div`
    position:fixed;
    left:0px;
    top:0px;
    width:100vw;
    height:100vh;
    background-color:#e5e5e5;
    z-index:1000;
    overflow:scroll;
    animation: fadeIn 0.2s ease;

    > div {
        max-width:720px;
        margin:0px auto;
        padding:140px;

        @media (max-width:768px){
            padding:80px 40px 40px 40px;
        }
    }

    @media (max-width:768px){
        
    background-color:#fff;
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const ContactUs = Styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    margin:60px 0px;
    padding: 20px 40px;
    border-radius: 100px;
    text-align: right;
    display:inline-block;
    left:50%;
    position:relative;
    transform:translateX(-50%);
    align-items: center;
`;

const CloseBtn = Styled.button`
    position:absolute;
    top:60px;
    right:60px;
    border:none;
    background-color:transparent;
    align-items: center;
    display:flex;
    color:rgba(153, 153, 153, 1);
    cursor:pointer;

    svg {
        margin-left:10px;
    }

    @media (max-width:768px){
        top:10px;
        right:10px;

        svg {
            transform: scale(.75);
            margin-left:5px;
        }
    }

`;



function Karriar() {

    const [pageData, setPageData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [visibleYrke, setVisibleYrke] = useState(false);
    const entry = "7JYGVdsgZqBvC6TtF3C9wy";

    useEffect(() => {
        client
            .getEntry(entry)
            .then((entry) => {
                    setPageData(entry);
                    setIsLoading(false);
                }
            ).catch(err => console.log(err));
    },[entry]);

    const roller = [];
    if(!isLoading){ 
        let i = 0;
        for (const [key, value] of pageData.fields.aktuellaroller.entries()) {
            roller.push(
            <div key={'footer'+i}>
                <Row direction="column">
                    <Col size={1} style={{ marginTop:'0px'}}>
                        {value.fields.titel}
                    </Col>
                    <Col size={2} style={{ marginTop:'0px'}}>
                        <ReactMarkdown remarkPlugins={[gfm]}>{value.fields.kortareBeskrivning}</ReactMarkdown>
                    </Col>
                    <Col size={1} style={{ textAlign: 'right', marginTop:'0px', alignItems: 'center', justifyContent: 'flex-end', display:'flex' }}>
                        <ReadMoreButton onClick={() => setVisibleYrke(key)}>
                            Läs Mer <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.5L5 4L1 7.5" stroke="#181618"/>
                            </svg>
                        </ReadMoreButton>
                    </Col>
                </Row>
                <Row>
                    <Col size={1} style={{ marginTop:'0px'}}>
                        <Breaker />
                    </Col>
                </Row>
            </div>
            );
            i++;
        }
    }
    return (
        <div>
        { isLoading && (
            <Loading />
        )}
        { !isLoading && (
            <div>
                { visibleYrke !== false && 
                    <YrkePopup>
                        <div>
                        <Image image={pageData.fields.aktuellaroller[visibleYrke].fields.bild.fields.file.url} />
                        <h1>
                            {pageData.fields.aktuellaroller[visibleYrke].fields.titel}
                        </h1>
                        <ReactMarkdown remarkPlugins={[gfm]}>{pageData.fields.aktuellaroller[visibleYrke].fields.fullBeskrivning}</ReactMarkdown>
                        <a href={"mailto:"+pageData.fields.epostFrKontakt}><ContactUs>{pageData.fields.epostFrKontakt}</ContactUs></a>
                        <CloseBtn onClick={() => setVisibleYrke(false)}>
                            <span>Stäng</span>
                            <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle opacity="0.05" cx="25" cy="25" r="25" fill="black"/>
                            <path d="M16 16L34 34" stroke="#999999"/>
                            <path d="M16 34L34 16" stroke="#999999"/>
                            </svg>
                        </CloseBtn>
                        </div>

                    </YrkePopup>
                 }
            <Image image={pageData.fields.storBild.fields.file.url} />
            <Grid>
                <Row direction="column">
                    <Col size={1}>
                    <h1>
                        {pageData.fields.titel}
                    </h1>
                    </Col>
                    <Col size={1}>
                        <ReactMarkdown remarkPlugins={[gfm]}>{pageData.fields.text}</ReactMarkdown>
                    </Col>
                </Row>
                <Row>
                    <Col size={1}>
                        <h2>Aktuella roller</h2>
                        <Breaker />
                    </Col>
                </Row>
                {roller}
                <TestimonialSplash>
                <TestimonialSplashContainer>
                    <h2>{pageData.fields.testimonial}</h2>
                    <ProfileCard image={pageData.fields.testimonialBild.fields.file.url}>
                        {pageData.fields.testimonialBild.fields.title}
                    </ProfileCard>
                </TestimonialSplashContainer>
            </TestimonialSplash>
            </Grid>
            </div>
            )}
        </div>
        
    );
}

export default Karriar;

