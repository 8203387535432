import React from 'react';
import Styled from 'styled-components';
import Image from '../../components/image';

const Container = Styled.div`
width: calc(100vw / 4 - 125px);
text-overflow: ellipsis;
overflow: hidden;

p { word-break: break-word;}

@media (max-width:1280px){
    width: calc(100vw / 3 - 126px);
}

@media (max-width:768px) {
    align-items: 'center';
    width:calc(100vw - 90px);
    p { display:inline-block; margin:0px 20px; width: calc(100% - 120px); top:0px; align-items: 'center'}
    img { height:100px; width:67px; }
    display:block;
}`;

function Medarbetare (props){
    return (
    <Container>
            { props.data.bild && (
                <Image image={props.data.bild.fields.file.url} sizes={"calc(100vw / 4 - 160px)"} />
            )}
        <p>
            {props.data.namn}<br/>
            {props.data.titel}<br/>
            {props.data.telefonnummer}<br/>
            {props.data.epost}<br/>
        </p>
    </Container>
    );
}

export default Medarbetare; 