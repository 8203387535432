import React, { useState, useEffect } from "react";
import { client } from '../../contentful/'
import Styled from 'styled-components';
import { Loading } from "../../components/Loading";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

function Home() {
    const [pageData, setPageData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const entry = "5ceaw1BS8eGsBec8S2mauE";

    useEffect(() => {
        client
            .getEntry("5ceaw1BS8eGsBec8S2mauE")
            .then((entry) => {
                    setPageData(entry);
                    setIsLoading(false);
                }
            ).catch(err => console.log(err));
    },[entry]);

    const CardContainer = Styled.div`
        position: absolute;
        left: calc( 100% / 3 * ${(props) => props.position[0]} + ${(props) => props.position[0] * 30}px);
        top: calc((100vw - 120px) / 5 * ${(props) => props.position[1]} + ${(props) => 60 * props.position[1]}px);
        overflow:hidden;
        background-color:#C0CCC8;
        background-image:url(${(props) => props.image}?fm=jpg&fl=progressive&w=${(props) => parseInt(props.ww / (3 / props.width[0]))});
        width:calc( 100% / ${(props) => 3 / props.width[0]} - ${(props) =>  60 - (30 * props.width[0]) }px);
        height:calc((100vw - 120px) / 5 * ${(props) => props.height[0]} + ${(props) => (60 * props.height[0]) - 60}px);
        background-size:cover;
        background-position:center center;

        a.overlay {
            display:block;
            left:0px;
            right:0px;
            position:absolute;
            width:100%;
            height:100%;
        }

        @media (max-width:1024px) {
            left: calc( 100% / 3 * ${(props) => props.position[0]} + ${(props) => props.position[0] * 15}px);
            width:calc( 100% / ${(props) => 3 / props.width[0]} - ${(props) =>  30 - (15 * props.width[0]) }px);
            top: calc((100vw - 120px) / 5 * ${(props) => props.position[1]} + ${(props) => 30 *props.position[1]}px);
            height:calc((100vw - 120px) / 5 * ${(props) => props.height[0]} + ${(props) => (30 * props.height[0]) - 30}px);
        }

        @media (max-width:768px) {
            left: calc( 100% / 2 * ${(props) => props.position[2]} + ${(props) => props.position[2] * 10}px);
            top: calc((100vw - 40px) / 3 * ${(props) => props.position[3]} + ${(props) => 20 * props.position[3]}px);
            width:calc( 100% / ${(props) => 2 / props.width[1]} - ${(props) =>  20 - 10 * props.width[1]  }px);
            height:calc((100vw - 40px) / 3 * ${(props) => props.height[1]} + ${(props) => (20 * props.height[1]) - 20}px);
            background-image:url(${(props) => props.image}?fm=jpg&fl=progressive&w=${(props) => parseInt(props.ww / (2 / props.width[1]))});
        }
    `;

    const CardHoverInfo = Styled.div`    
        position:absolute; 
        top:0px;
        left:0px;
        opacity:0;
        background-color:rgba(24, 22, 24, 0.41);
        width:100%;
        height:100%;
        margin:0;
        padding:0;
        transition: all .5s;
        &:hover {
            opacity:1;
        }

        p {
            position:absolute; top:50%;
            left:0;
            width:calc(100% - 40px);
            margin:0 20px;
            transform:translateY(-50%);
            text-align:center;
            color:rgba(246, 244, 242, 1);
    }
    `;
    const Container = Styled.div`
        position:relative;
        height:calc((100vw - 120px) / 5 * 7 + 420px );
        margin:0 30px;

        @media (max-width:768px) {
            height:calc((100vw - 40px) / 3 * 10 + 420px );
            margin:0 0px;
        }
    `;

    const ContactUsContainer = Styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-evenly;
    flex-wrap: nowrap;
    transform: translateY(-50%);
    top: calc(50% - 60px);
    position: relative;
    margin: 60px;
    
    @media (max-width:1024px){
        top: calc(50% - 30px);
        margin:30px;
    }`;

    const ContactUs = Styled.div`
    background-color: rgba(0, 0, 0, 0.05);
    padding: 10px 18px;
    border-radius: 100px;
    text-align: right;
    display:inline-block;
    align-items: center;
    margin-top:30px;
`;


    function Card (props){
        const windowWidth = window.innerWidth;
        return (
            <CardContainer position={props.position} ww={windowWidth} width={props.width} height={props.height} {...(props.image ? {image:props.image.fields.file.url} : {})} >
                { ( props.text && props.image ) &&
                    <CardHoverInfo><p>{props.text}</p>
                    { props.link && <a className="overlay" href={props.link} /> }
                    </CardHoverInfo> }
                { ( props.text && !props.image ) && <ContactUsContainer><ReactMarkdown remarkPlugins={[gfm]}>{props.text}</ReactMarkdown></ContactUsContainer> }
            </CardContainer>
        );
    }

    return (
        <div>
        { isLoading && (
            <Loading />
        )}
        { !isLoading && (
            <Container>
                <Card position={[0,0,0,0]} width={[1,1]} height={[1,1]} image={pageData.fields.bild1} text={pageData.fields.text1} link={pageData.fields.lnk1}/>
                <Card position={[1,0,1,0]} width={[1,1]} height={[1,1]} image={pageData.fields.bild2} text={pageData.fields.text2} link={pageData.fields.lnk2} />
                <Card position={[2,0,1,4]} width={[1,1]} height={[2,2]} image={pageData.fields.bild3} text={pageData.fields.text3} link={pageData.fields.lnk3} />
                <Card position={[0,1,0,2]} width={[2,2]} height={[2,2]} image={pageData.fields.bild4} text={pageData.fields.text4} link={pageData.fields.lnk4} />
                <Card position={[2,2,0,1]} width={[1,1]} height={[1,1]} image={pageData.fields.bild5} text={pageData.fields.text5} link={pageData.fields.lnk5} />
                <Card position={[0,3,1,1]} width={[1,1]} height={[1,1]} image={pageData.fields.bild6} text={pageData.fields.text6} link={pageData.fields.lnk6} />
                <Card position={[1,3,0,6]} width={[2,2]} height={[2,2]} image={pageData.fields.bild7} text={pageData.fields.text7} link={pageData.fields.lnk7} />
                <Card position={[0,4,0,5]} width={[1,1]} height={[2,1]} image={pageData.fields.bild8} text={pageData.fields.text8} link={pageData.fields.lnk8} />
                <CardContainer position={[1,5,0,8]} width={[2,2]} height={[2,3]}>
                    <ContactUsContainer>
                        <h2 style={{marginTop:"0"}}>{pageData.fields.textrutaTitel}</h2>
                        <p>{pageData.fields.textrutaText}</p>
                        <a href="/kontakt">
                            <ContactUs>Kontakta oss <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0.5L5 4L1 7.5" stroke="#181618"/>
                            </svg></ContactUs>
                        </a>
                    </ContactUsContainer>
                </CardContainer>
                <Card position={[0,6,0,4]} width={[1,1]} height={[1,1]} image={pageData.fields.bild9} text={pageData.fields.text9} />
            </Container>
            )}
        </div>
    );
}

export default Home;

