import React from 'react';

export function Instagram (props = {width:16, height:16}){
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 1.33333H4C2.52724 1.33333 1.33333 2.52724 1.33333 4V12C1.33333 13.4728 2.52724 14.6667 4 14.6667H12C13.4728 14.6667 14.6667 13.4728 14.6667 12V4C14.6667 2.52724 13.4728 1.33333 12 1.33333ZM4 0C1.79086 0 0 1.79086 0 4V12C0 14.2091 1.79086 16 4 16H12C14.2091 16 16 14.2091 16 12V4C16 1.79086 14.2091 0 12 0H4ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10ZM8 11.3333C9.84095 11.3333 11.3333 9.84095 11.3333 8C11.3333 6.15905 9.84095 4.66667 8 4.66667C6.15905 4.66667 4.66667 6.15905 4.66667 8C4.66667 9.84095 6.15905 11.3333 8 11.3333ZM12.3333 4.66667C12.8856 4.66667 13.3333 4.21895 13.3333 3.66667C13.3333 3.11438 12.8856 2.66667 12.3333 2.66667C11.781 2.66667 11.3333 3.11438 11.3333 3.66667C11.3333 4.21895 11.781 4.66667 12.3333 4.66667Z" fill="#181618"/>
    </svg>
    )
};

export function Facebook (props = {width:16, height:16}){
    return (
        <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M4 1.33333H12C13.4728 1.33333 14.6667 2.52724 14.6667 4V12C14.6667 13.4728 13.4728 14.6667 12 14.6667H4C2.52724 14.6667 1.33333 13.4728 1.33333 12V4C1.33333 2.52724 2.52724 1.33333 4 1.33333ZM0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4ZM11.9868 5.14659C12.2802 5.14659 12.4535 5.18659 12.5468 5.21325V3.71992C12.4002 3.65326 12.0402 3.58659 11.6668 3.58659C10.2135 3.58659 9.28018 4.54659 9.28018 6.02659V6.77326H8.18685V8.29325H9.28018V13.3333H11.0668V8.29325H12.3467L12.8534 6.77326H11.0668V6.05326C11.0668 5.31992 11.5868 5.14659 11.9868 5.14659Z" fill="#181618"/>
</svg>

    )
};

export function LinkedIn (props = {width:16, height:16}){
    return (
    <svg width={props.width} height={props.height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M4 1.33333H12C13.4728 1.33333 14.6667 2.52724 14.6667 4V12C14.6667 13.4728 13.4728 14.6667 12 14.6667H4C2.52724 14.6667 1.33333 13.4728 1.33333 12V4C1.33333 2.52724 2.52724 1.33333 4 1.33333ZM0 4C0 1.79086 1.79086 0 4 0H12C14.2091 0 16 1.79086 16 4V12C16 14.2091 14.2091 16 12 16H4C1.79086 16 0 14.2091 0 12V4ZM5.58933 6.60267V11.8507H4.17067V6.60267H5.58933ZM4.87467 5.74933C4.39467 5.74933 4 5.35467 4 4.88533C4 4.39467 4.39467 4 4.87467 4C5.36533 4 5.74933 4.39467 5.74933 4.88533C5.74933 5.35467 5.36533 5.74933 4.87467 5.74933ZM9.50817 7.744C8.8895 7.744 8.52683 8.224 8.52683 8.832V11.8507H7.10817V6.60267H8.48417V7.25333C8.80417 6.70933 9.4335 6.464 9.99883 6.464C11.3002 6.464 11.8975 7.392 11.8975 8.544V11.8507H10.4788V8.78933C10.4788 8.20267 10.1908 7.744 9.50817 7.744Z" fill="#181618"/>
    </svg>
    )
};